* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}
.app {
  height: 100vh;
  background: #f2f3f8;
  overflow-y: scroll;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
:root {
  --red: #f40000;
  --bg-card: #f6f5f8;
  --bg-primary: #f2f3f8;
  --bg-white: #ffffff;
  --border-gray: #d8dfeb;
  --font-black: #000000;
  --button-gray: #d9d9d9;
  --button-green: #0ea801;
  --text-white: #f6f5f8;
  --border-black: #000000;
  --bg-black: #000000;
  --bg-green: #0ea801;
  --blue-color: #10305b;
  --green-color: #1d650e;
}
